import {get, omit, toNumber, toString} from 'lodash-es';

export default [
  {
    path: '/spa/',
    name: 'spa/ApplicationListView',
    meta: { title: 'Заявки на доступ в ЛК Поставщика' },
    props: route => ({
      filter: omit( get(route, 'query'), ['page', 'size']),
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/spa/ApplicationListView/ApplicationListView'),
  },
  {
    path: '/spa/application/:application?',
    name: 'spa/ApplicationDetailView',
    meta: { title: 'Заявка' },
    props: route => ({
      id: toString(get(route, 'params.application')),
    }),
    component: () => import('@/views/spa/ApplicationDetailView/ApplicationDetailView'),
  },
  {
    path: '/documents/:catchAll(.*)',
    redirect: { name: 'user/ProfileView' }
  },
];
