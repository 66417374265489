import {get, map, toNumber, toString, filter} from 'lodash-es';

export const pkoList = (result) => ({
  additional: {
    title: toString(get(result, 'additional.title')),
    messages: filter(map(get(result, 'additional.messages', []), (mes) => toString(mes))),
  },
  items: map(get(result, 'items'), (item) => ({
    name: toString(get(item, 'name')),
    description: toString(get(item, 'description')),
    icon: get(item, 'icon', 'mdi-check-decagram'),
    link: toString(get(item, 'link')),
    help: {
      name: toString(get(item, 'help.name')),
      email: toString(get(item, 'help.email')),
      phone: toString(get(item, 'help.phone')),
    },
    instruction: toString(get(item, 'instruction')),
    additional: {
      survey: {
        active: get(item, 'additional.survey.active', false),
        link: get(item, 'additional.survey.url', ''),
      }
    },
    statuses: map(get(item, 'statuses', []), (status) => ({
      name: toString(get(status, 'name')),
      status: {
        code: toString(get(status, 'status.xmlId')),
        name: toString(get(status, 'status.name')),
      },
      date: toString(get(status, 'dateEnd')),
    })),
  })),
  count: toNumber(get(result, 'navCount', 0)),
});