import { mapValues, isArray, map } from 'lodash-es';
import Api from '@/api';
import {
  counterpartyDetail,
  counterpartyList,
  sectionPayload,
  commentList,
  registryList,
  companiesList,
  approvalList,
  approvalHistoryList, counterpartyViewedQuestions, risksList, registryCurrent
} from './selectors';

class PKOMAIN extends Api {
  counterpartyList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/pkomain/form/get-list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(counterpartyList);
  }
  counterpartyDetailWithOutApproval({ id, version }) {
    return this.rest('/pkomain/form/get.json',{
      method: 'POST',
      data: {
        id,
        version,
      },
    })
    .then(counterpartyDetail);
  }
  registryList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/pkomain/userstatusregistry/list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(registryList);
  }
  downloadRegistryList({ page = 1, size = 10, filter = {} }) {
    return this.download('/pkomain/userstatusregistry/list-excel.json', {
      params: { page, size, filter },
    });
  }
  createCounterparty({ code }) {
    return this.rest('/pkomain/form/create.json', {
      method: 'POST',
      data: { companyCode: code },
    });
  }
  updateCounterpartyWithOutApproval({ id, items }) {
    return this.rest('/pkomain/form/edit.json', {
      method: 'POST',
      data: {
        id, // TODO: id
        items: mapValues(items, sectionPayload),
      },
    }).then(counterpartyDetail);
  }
  agreementCounterparty({ id }) {
    return this.rest('/pkomain/form/send-on-approvement.json', {
      method: 'POST',
      data: {
        id,
      },
    });
  }
  commentList({ id }) {
    return this.rest('/pkomain/comment/get-list.json', {
      method: 'POST',
      data: { formId: id },
    }).then(commentList);
  }
  createComment({ id, question, message }) {
    return this.rest('/pkomain/comment/create-many.json', {
      method: 'POST',
      data: {
        formId: id,
        comments: map(isArray(question) ? question : [question], (code) => ({
          message,
          questionXmlId: code,
        }))
      },
    });
  }
  deleteComment({ id, comments }) {
    return this.rest('/pkomain/comment/delete-many.json', {
      method: 'POST',
      data: {
        formId: id,
        comments: isArray(comments) ? comments : [comments],
      },
    });
  }
  editComment({ id, message }) {
    return this.rest('/pkomain/comment/edit-comment.json', {
      method: 'POST',
      data: {
        id,
        message: message,
      },
    });
  }
  sendDecision({ id, decision, comment, notation } ) {
    return this.rest('/pkomain/form/save-decision.json', {
      method: 'POST',
      data: {
        id: id,
        decision: decision, /// YES, REWORK, NO
        publicComment: comment,
        internalComment: notation,
      },
    })
  }
  companiesForCreate() {
    return this.rest('/pkomain/form/get-companies-for-create').then(companiesList);
  }
  approvalStatus({ id, version }) {
    return this.rest('/pkomain/form/get-approval-timeline.json', {
      method: 'POST',
      data: {
        id: id,
        version: version
      },
    }).then(approvalList)
  }
  approvalHistory({ id }) {
    return this.rest('/pkomain/form/get-approval-history.json', {
      method: 'POST',
      data: {
        id: id,
      },
    }).then(approvalHistoryList)
  }
  counterpartyDetail({ id, version }) {
    return Promise.all([
      this.counterpartyDetailWithOutApproval({ id, version }),
      this.approvalStatus({ id, version }),
      this.approvalHistory({ id }),
    ]).then(([counterparty, approval, approvalHistory]) => {
      return {
        approval,
        approvalHistory,
        ...counterparty,
      }
    })
  }
  updateCounterparty({ id, items }) {
    return this.updateCounterpartyWithOutApproval({ id, items }).then((counterparty) => {
      return this.approvalStatus({ id }).then((approval) => ({
        approval,
        ...counterparty,
      }))
    })
  }
  downloadTable({ format = 'zip', id, code }) {
    return this.download(`/pkomain/form/download-${format}.json`, {
      params: { id, questionCode: code },
    });
  }
  downloadFormZip({ id }) {
    return this.download(`/pkomain/form/download-form-zip.json`, {
      params: { id },
    });
  }
  fetchCounterpartyViewedQuestions({ id }) {
    return this.rest('/pkomain/form/get-viewed-questions.json', {
      params: {
        id: id,
      },
    }).then(counterpartyViewedQuestions)
  }
  setCounterpartyViewedQuestions({ id, questions }) {
    return this.rest('/pkomain/form/add-viewed-questions.json', {
      method: 'POST',
      data: {
        id: id,
        questions: questions,
      },
    }).then(counterpartyViewedQuestions)
  }
  fetchRisks() {
    return this.rest('/pkomain/risk/list.json', {
    }).then(risksList)
  }
  registryCurrent() {
    return this.rest('/pkomain/userstatusregistry/current.json', {
    }).then(registryCurrent);
  }
}

export default new PKOMAIN();
