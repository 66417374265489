import { includes, findIndex, get } from 'lodash-es';
import api from './api';

const START = 'START';
const END = 'END';


const SET_COMMENT_LIST = 'SET_COMMENT_LIST';
const UPDATE_COMMENT = 'UPDATE_COMMENT';
const SET_APPLICATION_LIST = 'SET_APPLICATION_LIST';
const SET_APPLICATION_DETAIL = 'SET_APPLICATION_DETAIL';
const SET_INSPECTOR_LIST = 'SET_INSPECTOR_LIST';
const SET_REPORT_LIST = 'SET_REPORT_LIST';
const SET_REPORT_DETAIL = 'SET_REPORT_DETAIL';
const SET_CHAT_LIST = 'SET_CHAT_LIST';
const UPDATE_CHAT = 'UPDATE_CHAT';
const SET_TIMELINE = 'SET_TIMELINE';

export default {
  namespaced: true,
  state: {
    pending: {
      deleteApplication: false,
      createApplication: false,
      updateApplication: false,
      fetchApplicationList: false,
      fetchApplicationDetail: false,
      approveApplication: false,
      fetchTimeline: false,
      fetchInspectorList: false,
      inviteInspector: false,
      sendInviteInspector: false,
      createComment: false,
      fetchCommentList: false,
      approveComment: false,
      editComment: false,
      decisionInspector: false,
      fetchReportList: false,
      createReport: false,
      updateReport: false,
      deleteComment: false,
      finishApplication: false,
      createChatComment: false,
      editChatComment: false,
      fetchChatList: false,
      viewedChatList: false,
      getUserByEmail: false,
      downloadApplicationList: false,
      cancelInviteInspector: false,
      delegateInspector: false,
    },
    comment: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
    chat: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
    inspector: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
    application: {
      current: null,
      list: {
        items: [],
        count: 0,
      },
      timeline: [],
    },
    report: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
  },
  getters: {
    pending: (state) => {
      return includes(state.pending, true);
    },
    application: (state) => {
      return state.application.current;
    },
    applications: (state) => {
      return state.application.list;
    },
    applicationTimeline: (state) => {
      return state.application.timeline;
    },
    inspectors: (state) => {
      return state.inspector.list;
    },
    comments: (state) => {
      return state.comment.list;
    },
    chat: (state) => {
      return state.chat.list;
    },
    report: (state) => {
      return state.report.current;
    },
    reports: (state) => {
      return state.report.list;
    },
  },
  mutations: {
    [START]: (state, key) => {
      state.pending[key] = true;
    },
    [END]: (state, key) => {
      state.pending[key] = false;
    },
    [SET_INSPECTOR_LIST]: (state, list) => {
      state.inspector.list = list;
    },
    [SET_APPLICATION_LIST]: (state, list) => {
      state.application.list = list;
    },
    [SET_TIMELINE]: (state, list) => {
      state.application.timeline = list;
    },
    [SET_APPLICATION_DETAIL]: (state, detail) => {
      state.application.current = detail;
    },
    [SET_REPORT_LIST]: (state, list) => {
      state.report.list = list;
    },
    [SET_REPORT_DETAIL]: (state, detail) => {
      state.report.current = detail;
    },
    [SET_COMMENT_LIST]: (state, list) => {
      state.comment.list = list;
    },
    [UPDATE_COMMENT]: (state, { id, message }) => {
      const list = get(state, 'comment.list.items');
      const index = findIndex(list, { id });
      if (~index) {
        const item = get(state, ['comment', 'list', 'items', index]);
        item.message = message;
      }
    },
    [SET_CHAT_LIST]: (state, list) => {
      state.chat.list = list;
    },
    [UPDATE_CHAT]: (state, { id, message }) => {
      const list = get(state, 'chat.list.items');
      const index = findIndex(list, { id });
      if (~index) {
        const item = get(state, ['chat', 'list', 'items', index]);
        item.message = message;
      }
    }
  },
  actions: {
    fetchInspectorList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchInspectorList');
      return api.getInspectorList({ page, size, filter }).then(list => {
        commit(SET_INSPECTOR_LIST, list);
        commit(END, 'fetchInspectorList');
        return list;
      }).catch((e) => {
        commit(END, 'fetchInspectorList');
        throw e;
      });
    },
    inviteInspector: ({ commit }, { application, inspectors }) => {
      commit(START, 'inviteInspector');
      return api.inviteInspector({ application, inspectors }).then(list => {
        commit(END, 'inviteInspector');
        return list;
      }).catch((e) => {
        commit(END, 'inviteInspector');
        throw e;
      });
    },
    sendInviteInspector: ({ commit }, { application, inspectors }) => {
      commit(START, 'sendInviteInspector');
      return api.sendInviteInspector({ application, inspectors }).then(list => {
        commit(END, 'sendInviteInspector');
        return list;
      }).catch((e) => {
        commit(END, 'sendInviteInspector');
        throw e;
      });
    },
    cancelInviteInspector: ({ commit }, { comment, application, inspectors }) => {
      commit(START, 'cancelInviteInspector');
      return api.cancelInviteInspector({ application, comment, inspectors }).then(list => {
        commit(END, 'cancelInviteInspector');
        return list;
      }).catch((e) => {
        commit(END, 'cancelInviteInspector');
        throw e;
      });
    },
    decisionInspector: ({ commit }, { application, decision, comment, inspector }) => {
      commit(START, 'decisionInspector');
      return api.decisionInspector({ application, decision, comment, inspector }).then(list => {
        commit(END, 'decisionInspector');
        return list;
      }).catch((e) => {
        commit(END, 'decisionInspector');
        throw e;
      });
    },
    delegateInspector: ({ commit }, { application, comment, inspector }) => {
      commit(START, 'delegateInspector');
      return api.delegateInspector({ application, comment, inspector }).then(list => {
        commit(END, 'delegateInspector');
        return list;
      }).catch((e) => {
        commit(END, 'delegateInspector');
        throw e;
      });
    },
    fetchApplicationDetail: ({ commit }, { id }) => {
      commit(START, 'fetchApplicationDetail');
      return api.getApplicationDetail({ id }).then(detail => {
        commit(SET_APPLICATION_DETAIL, detail);
        commit(END, 'fetchApplicationDetail');
        return detail;
      }).catch((e) => {
        commit(END, 'fetchApplicationDetail');
        throw e;
      });
    },
    fetchApplicationList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchApplicationList');
      return api.getApplicationList({ page, size, filter }).then(list => {
        commit(SET_APPLICATION_LIST, list);
        commit(END, 'fetchApplicationList');
        return list;
      }).catch((e) => {
        commit(END, 'fetchApplicationList');
        throw e;
      });
    },
    downloadApplicationList: ({ commit }, { page, size, filter }) => {
      commit(START, 'downloadApplicationList');
      return api.downloadApplicationList({ page, size, filter }).then(list => {

        commit(END, 'downloadApplicationList');
        return list;
      }).catch((e) => {
        commit(END, 'downloadApplicationList');
        throw e;
      });
    },
    createApplication: ({ commit }) => {
      commit(START, 'createApplication');
      return api.createApplication().then((application) => {
        commit(END, 'createApplication');
        return application;
      }).catch((e) => {
        commit(END, 'createApplication');
        throw e;
      });
    },
    deleteApplication: ({ commit }, { id }) => {
      commit(START, 'deleteApplication');
      return api.deleteApplication({ id }).then((result) => {
        commit(END, 'deleteApplication');
        return result;
      }).catch((e) => {
        commit(END, 'deleteApplication');
        throw e;
      });
    },
    approveApplication: ({ commit }, { id, action, comment }) => {
      commit(START, 'approveApplication');
      return api.approveApplication({ id, action, comment }).then((application) => {
        commit(END, 'approveApplication');
        return application;
      }).catch((e) => {
        commit(END, 'approveApplication');
        throw e;
      });
    },
    finishApplication: ({commit}, { id, comment, name, rating, fail }) => {
      commit(START, 'finishApplication');
      return api.finishApplication({ id, comment, rating, name, fail }).then((application) => {
        commit(END, 'finishApplication');
        return application;
      }).catch((e) => {
        commit(END, 'finishApplication');
        throw e;
      });
    },
    updateApplication: ({ commit }, { id, fields }) => {
      commit(START, 'updateApplication');
      return api.updateApplication({ id, fields }).then((application) => {
        commit(END, 'updateApplication');
        return application;
      }).catch((e) => {
        commit(END, 'updateApplication');
        throw e;
      });
    },
    fetchCommentList: ({ commit }, { id }) => {
      commit(START, 'fetchCommentList');
      return api.getCommentList({ id }).then(list => {
        commit(SET_COMMENT_LIST, list);
        commit(END, 'fetchCommentList');
        return list;
      }).catch((e) => {
        commit(END, 'fetchCommentList');
        throw e;
      });
    },
    createComment: ({ commit }, { application, parent, type, code, message }) => {
      commit(START, 'createComment');
      return api.createComment({ application, parent, type, code, message }).then((result) => {
        commit(END, 'createComment');
        return result;
      }).catch((e) => {
        commit(END, 'createComment');
        throw e;
      });
    },
    deleteComment: ({ commit }, { id }) => {
      commit(START, 'deleteComment');
      return api.deleteComment({ id }).then((result) => {
        commit(END, 'deleteComment');
        return result;
      }).catch((e) => {
        commit(END, 'deleteComment');
        throw e;
      });
    },
    approveComment: ({ commit }, { items }) => {
      commit(START, 'approveComment');
      return api.approveComment({ items }).then((result) => {
        commit(END, 'approveComment');
        return result;
      }).catch((e) => {
        commit(END, 'approveComment');
        throw e;
      });
    },
    editComment: ({ commit }, { id, message }) => {
      commit(START, 'editComment');
      return api.editComment({ id, message }).then((result) => {
        commit(UPDATE_COMMENT, { id, message });
        commit(END, 'editComment');
        return result;
      }).catch((e) => {
        commit(END, 'editComment');
        throw e;
      });
    },
    fetchReportList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchReportList');
      return api.getReportList({ page, size, filter }).then(list => {
        commit(SET_REPORT_LIST, {
          items: list,
          count: list.length,
        });
        commit(END, 'fetchReportList');
        return list;
      }).catch((e) => {
        commit(END, 'fetchReportList');
        throw e;
      });
    },
    createReport: ({ commit }, { application, name, comment, hasTimeDeviation, hasQualityDeviation, files }) => {
      commit(START, 'createReport');
      return api.createReport({ application, name, comment, hasTimeDeviation, hasQualityDeviation, files }).then((report) => {
        commit(END, 'createReport');
        return report;
      }).catch((e) => {
        commit(END, 'createReport');
        throw e;
      });
    },
    updateReport: ({ commit }, { id, name, comment, hasTimeDeviation, hasQualityDeviation, files }) => {
      commit(START, 'updateReport');
      return api.updateReport({ id, name, comment, hasTimeDeviation, hasQualityDeviation, files }).then((report) => {
        commit(END, 'updateReport');
        return report;
      }).catch((e) => {
        commit(END, 'updateReport');
        throw e;
      });
    },
    //
    fetchChatList: ({ commit }, { id }) => {
      commit(START, 'fetchChatList');
      return api.getChatList({ id }).then(list => {
        commit(SET_CHAT_LIST, list);
        commit(END, 'fetchChatList');
        return list;
      }).catch((e) => {
        commit(END, 'fetchChatList');
        throw e;
      });
    },
    createChatComment: ({ commit }, { application, parent, message }) => {
      commit(START, 'createChatComment');
      return api.createChatComment({ application, parent, message }).then((result) => {
        commit(END, 'createChatComment');
        return result;
      }).catch((e) => {
        commit(END, 'createChatComment');
        throw e;
      });
    },
    editChatComment: ({ commit }, { id, message }) => {
      commit(START, 'editChatComment');
      return api.editChatComment({ id, message }).then((result) => {
        commit(UPDATE_CHAT, { id, message });
        commit(END, 'editChatComment');
        return result;
      }).catch((e) => {
        commit(END, 'editChatComment');
        throw e;
      });
    },
    viewedChatList: ({ commit }, { id }) => {
      commit(START, 'viewedChatList');
      return api.viewedChatList({ id }).then((result) => {
        commit(END, 'viewedChatList');
        return result;
      }).catch((e) => {
        commit(END, 'viewedChatList');
        throw e;
      });
    },
    fetchTimeline: ({ commit }, { id }) => {
      commit(START, 'fetchTimeline');
      return api.getApplicationTimeline({ id }).then(list => {
        commit(SET_TIMELINE, list);
        commit(END, 'fetchTimeline');
        return list;
      }).catch((e) => {
        commit(END, 'fetchTimeline');
        throw e;
      });
    },
    getUserByEmail: ({ commit }, { email, code }) => {
      commit(START, 'getUserByEmail');
      return api.getUserByEmail({ email, code  }).then(list => {
        commit(END, 'getUserByEmail');
        return list;
      }).catch((e) => {
        commit(END, 'getUserByEmail');
        throw e;
      });
    },
  },
}
