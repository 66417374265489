import store from '@/store';

const beforeEnter = (to, from, next) => {
  if (!to.name.indexOf('user/') && to.name !== 'user/ProfileView' && store.getters['user/current']) {
    next({ name: 'user/ProfileView' });
  } else {
    next();
  }
}

export default process.env.VUE_APP_DISABLED_USER ? [] : [
  {
    path: '/',
    name: 'user/ProfileView',
    meta: {
      title: 'Информация о пользователе',
    },
    component: () => import('@/views/user/ProfileView/ProfileView'),
  },
  {
    path: '/registration',
    name: 'user/UserRegistrationView',
    meta: {
      title: 'Регистрация пользователя',
    },
    component: () => import('@/views/user/UserRegistrationView/UserRegistrationView'),
    beforeEnter,
  },
  {
    path: '/forgot',
    alias: [
      '/forgot.php'
    ],
    name: 'user/UserForgotView',
    meta: {
      title: 'Информация о пользователе',
    },
    component: () => import('@/views/user/UserForgotView/UserForgotView'),
    beforeEnter,
  },
  {
    path: '/auth',
    alias: [
      '/auth/index.php'
    ],
    name: 'user/UserPasswordChangeView',
    meta: {
      title: 'Смена пароля',
    },
    component: () => import('@/views/user/UserPasswordChangeView/UserPasswordChangeView'),
    beforeEnter,
  },
  {
    path: '/user/:catchAll(.*)',
    redirect: { name: 'user/ProfileView' }
  },
];
