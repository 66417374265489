import { get, toString, mapValues, map } from 'lodash-es';

export const ndaItem = (result) => {
  return mapValues(result, (item) => ({
    label: toString(get(item, 'label')),
    values: map(get(item, 'values'), (item) => mapValues(item, (item) => ({
      label: toString(get(item, 'label')),
      value: get(item, 'value'),
      options: map(get(item, 'options'), (item) => ({
        text: get(item, 'text'),
        value: get(item, 'value'),
      }))
    })))
  }));
};
