<template>
  <LayoutNotFound v-if="$route.name === 'NotFound'" />
  <LayoutAuth v-else-if="!user" />
  <LayoutDefault v-else />
</template>

<script>
import LayoutAuth from '@/layouts/LayoutAuth/LayoutAuth';
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault';
import { mapGetters } from 'vuex';
import LayoutNotFound from '@/layouts/LayoutNotFound/LayoutNotFound';
export default {
  name: 'App',
  components: {
    LayoutAuth,
    LayoutDefault,
    LayoutNotFound,
  },
  computed: {
    ...mapGetters({
      'user': 'user/current',
    }),
  },
  mounted() {
    const globalNotification = window.globalNotification;
    if (globalNotification) {
      this.$notify({
        type: globalNotification['type'] || 'success',
        title: globalNotification['title'] || '',
        text: globalNotification['text'] || '',
        duration: 30000,
      });
    }
  }
}
</script>

<style lang="scss">
.swal2-container {
  font-family: "Roboto", sans-serif;
  .swal2-title {
    font-size: 24px;
    font-weight: 500;
  }
  .swal2-styled {
    text-transform: uppercase;
    &:focus {
      box-shadow: none !important;
    }
  }
  .swal2-styled.swal2-cancel {
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>
