import {isArray, map, mapValues} from 'lodash-es';
import Api from '@/api';
import {counterpartyDetail, counterpartyList, sectionPayload, commentList, registryList} from './selectors';
import {approvalHistoryList} from '@/store/pkomain/selectors';

class PKOPIR extends Api {
  counterpartyList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/pkopir/form/get-list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(counterpartyList);
  }
  counterpartyDetailWithOutApproval({ id }) {
    return this.rest('/pkopir/form/get.json',{
      method: 'POST',
      data: {
        id,
      },
    })
      .then(counterpartyDetail);
  }
  counterpartyDetail({ id }) {
    return Promise.all([
      this.counterpartyDetailWithOutApproval({ id }),
      this.approvalHistory({ id }),
    ]).then(([counterparty, approvalHistory]) => {
      return {
        approvalHistory,
        ...counterparty,
      }
    });
  }
  approvalHistory({ id }) {
    return this.rest('/pkopir/form/get-approval-history.json', {
      method: 'POST',
      data: {
        id: id,
      },
    }).then(approvalHistoryList)
  }
  registryList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/pkopir/userstatusregistry/list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(registryList);
  }
  downloadRegistryList({ page = 1, size = 10, filter = {} }) {
    return this.download('/pkopir/userstatusregistry/list-excel.json', {
      params: { page, size, filter },
    });
  }
  createCounterparty() {
    return this.rest('/pkopir/form/create.json').then(counterpartyDetail);
  }
  updateCounterparty({ id, items }) {
    return this.rest('/pkopir/form/edit.json', {
      method: 'POST',
      data: {
        id,
        items: mapValues(items, sectionPayload),
      },
    }).then(counterpartyDetail);
  }
  agreementCounterparty({ id }) {
    return this.rest('/pkopir/form/send-on-approvement.json', {
      method: 'POST',
      data: {
        id,
      },
    });
  }
  commentList({ id }) {
    return this.rest('/pkopir/comment/get-list.json', {
      method: 'POST',
      data: { formId: id },
    }).then(commentList);
  }
  createComment({ id, question, message }) {
    return this.rest('/pkopir/comment/create-many.json', {
      method: 'POST',
      data: {
        formId: id,
        comments: map(isArray(question) ? question : [question], (code) => ({
          message,
          questionXmlId: code,
        }))
      },
    });
  }
  deleteComment({ id, comments }) {
    return this.rest('/pkopir/comment/delete-many.json', {
      method: 'POST',
      data: {
        formId: id,
        comments: isArray(comments) ? comments : [comments],
      },
    });
  }
  sendDecision({ id, decision, comment, notation, expertMark } ) {
    return this.rest('/pkopir/form/save-decision.json', {
      method: 'POST',
      data: {
        id: id,
        decision: decision, /// YES, REWORK, NO
        publicComment: comment,
        internalComment: notation,
        expertMark: expertMark,
      },
    })
  }
}

export default new PKOPIR();
