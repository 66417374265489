import api from './api';
import {includes, some} from 'lodash-es';
const START = 'START';
const END = 'END';

const SET_DETAIL = 'SET_DETAIL';
const SET_LIST = 'SET_LIST';
const SET_TIMELINE = 'SET_TIMELINE';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchItem: false,
      fetchList: false,
      fetchTimeline: false,
      createItem: false,
      updateItem: false,
      deleteItem: false,
      applyAction: false,
    },
    detail: {},
    timeline: [],
    list: {
      count: 0,
      items: [],
    },
  },
  getters: {
    detail: (state) => {
      return state.detail;
    },
    list: (state) => {
      return state.list;
    },
    timeline: (state) => {
      return state.timeline;
    },
    pending: (state) => {
      return includes(state.pending, true);
    },
    loading: (state, keys) => {
      return some(keys, (key) => state.pending[key])
    },
    hasStatus(state) {
      return (status) => status === state.detail.status;
    },
    hasType(state) {
      return (type) => type === state.detail.type;
    },
    hasAction(state) {
      return (actions) => some(actions, (action) => includes(state.detail.availableActions, action));
    }
  },
  mutations: {
    [START]: (state, key) => {
      state.pending[key] = true;
    },
    [END]: (state, key) => {
      state.pending[key] = false;
    },
    [SET_DETAIL]: (state, detail) => {
      state.detail = detail;
    },
    [SET_LIST]: (state, list) => {
      state.list = list;
    },
    [SET_TIMELINE]: (state, timeline) => {
      state.timeline = timeline;
    },
  },
  actions: {
    getAccountApplicationDetail: ({ commit }, { id }) => {
      commit(START, 'fetchItem');
      return api.getAccountApplicationDetail({ id }).then(detail => {
        commit(SET_DETAIL, detail);
        commit(END, 'fetchItem');
        return detail;
      }).catch(() => {
        commit(END, 'fetchItem');
      });
    },
    getAccountApplicationList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchList');
      return api.getAccountApplicationList({ page, size, filter }).then(list => {
        commit(SET_LIST, list);
        commit(END, 'fetchList');
        return list;
      }).catch(() => {
        commit(END, 'fetchList');
      });
    },
    fetchTimeline: ({ commit }, { id }) => {
      commit(START, 'fetchTimeline');
      return api.fetchTimeline({ id }).then(timeline => {
        commit(SET_TIMELINE, timeline);
        commit(END, 'fetchTimeline');
        return timeline;
      }).catch(() => {
        commit(END, 'fetchTimeline');
      });
    },
    createApplicationAccount: ({ commit }, { type } ) => {
      commit(START, 'createItem');
      return api.createAccountApplication({ type }).then(detail => {
        commit(SET_DETAIL, detail);
        commit(END, 'createItem');
        return detail;
      }).catch(() => {
        commit(END, 'createItem');
      });
    },
    updateAccountApplication: ({ commit }, { id, fields }) => {
      commit(START, 'updateItem');
      return api.updateAccountApplication({ id, fields }).then((result) => {
        commit(END, 'updateItem');
        return result;
      }).catch(() => {
        commit(END, 'updateItem');
      });
    },
    applyActionAccountApplication: ({ commit }, { id, actionCode, comment = '' }) => {
      commit(START, 'applyAction');
      return api.applyActionAccountApplication({ id, actionCode, comment }).then((result) => {
        commit(END, 'applyAction');
        return result;
      }).catch(() => {
        commit(END, 'applyAction');
      });
    },
    deleteAccountApplication: ({ commit }, { id }) => {
      commit(START, 'deleteItem');
      return api.deleteAccountApplication({ id }).then((result) => {
        commit(END, 'deleteItem');
        return result;
      }).catch(() => {
        commit(END, 'deleteItem');
      });
    },
  }
}
