<template>
  <notifications class="mt-3" :position="['center', 'top']" :width="$vuetify.breakpoint.name === 'xs' ? '100%': '50%'">
    <template  v-slot:body="{item: { id, title, text, type, state, speed, length, data}, close}">
      <VAlert :type="type" @close="close" dense dismissible :icon="false">
        <div class="v-messages">
          <div v-if="title" class="font-weight-bold mb-1">{{title}}</div>
          <div v-html="text" />
        </div>
      </VAlert>
    </template>
  </notifications>
</template>

<script>
export default {
  name: 'NotificationLog'
}
</script>

<style scoped>

</style>
