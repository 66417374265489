import {debounce, findIndex, get, groupBy, includes} from 'lodash-es';
import api from './api';

const START = 'START';
const END = 'END';

const SET_REGISTRY_LIST = 'SET_REGISTRY_LIST';
const SET_REGISTRY_CURRENT = 'SET_REGISTRY_CURRENT';

const SET_COUNTERPARTY_LIST = 'SET_COUNTERPARTY_LIST';
const SET_COUNTERPARTY_CURRENT = 'SET_COUNTERPARTY_CURRENT';
const SET_COUNTERPARTY_VIEWED_QUESTIONS = 'SET_COUNTERPARTY_VIEWED_QUESTIONS';

const SET_COMMENT_LIST = 'SET_COMMENT_LIST';
const SET_COMMENT_CURRENT = 'SET_COMMENT_CURRENT';
const SET_UPDATE_COMMENT = 'SET_UPDATE_COMMENT';

const SET_RISKS_LIST = 'SET_RISKS_LIST';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchRegistryList: false,
      fetchCounterpartyList: false,
      fetchCounterpartyDetail: false,
      createCounterparty: false,
      updateCounterparty: false,
      agreementCounterparty: false,
      fetchCommentList: false,
      createComment: false,
      deleteComment: false,
      sendDecision: false,
      downloadRegistryList: false,
      fetchViewedQuestions: false,
      setViewedQuestions: false,
      risksList: false,
      fetchRegistryCurrent: false,
    },
    counterparty: {
      current: null,
      list: {
        items: [],
        count: 0,
      },
      viewedQuestions: null,
      risksList: null,
    },
    registry: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
    comment: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
  },
  getters: {
    pending: (state) => {
      return includes(state.pending, true);
    },
    counterparty: (state) => {
      return get(state, 'counterparty.current');
    },
    counterpartyViewedQuestions: (state) => {
      return get(state, 'counterparty.viewedQuestions', {});
    },
    counterparties: (state) => {
      return state.counterparty.list;
    },
    registries: (state) => {
      return state.registry.list;
    },
    registry: (state) => {
      return get(state, 'registry.current');
    },
    comment: (state) => {
      return state.comment.current;
    },
    comments: (state) => {
      return groupBy(state.comment.list.items, 'question.id');
    },
    risks: (state) => {
      return get(state, 'counterparty.risksList', []);
    }
  },
  mutations: {
    [START]: (state, key) => {
      state.pending[key] = true;
    },
    [END]: (state, key) => {
      state.pending[key] = false;
    },
    [SET_COUNTERPARTY_LIST]: (state, list) => {
      state.counterparty.list = list;
    },
    [SET_COUNTERPARTY_CURRENT]: (state, current) => {
      state.counterparty.current = current;
    },
    [SET_COUNTERPARTY_VIEWED_QUESTIONS]: (state, viewedQuestions) => {
      state.counterparty.viewedQuestions = viewedQuestions;
    },
    [SET_REGISTRY_LIST]: (state, list) => {
      state.registry.list = list;
    },
    [SET_REGISTRY_CURRENT]: (state, current) => {
      state.registry.current = current;
    },
    [SET_COMMENT_LIST]: (state, list) => {
      state.comment.list = list;
    },
    [SET_COMMENT_CURRENT]: (state, current) => {
      state.comment.current = current;
    },
    [SET_RISKS_LIST]: (state, list) => {
      state.counterparty.risksList = list;
    },
    [SET_UPDATE_COMMENT]: (state, { id, message }) => {
      const list = get(state, 'comment.list.items');
      const index = findIndex(list, { id });
      if (~index) {
        const item = get(state, ['comment', 'list', 'items', index]);
        item.message = message;
      }
    },
  },
  actions: {
    fetchCounterpartyList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchCounterpartyList');
      return api.counterpartyList({ page, size, filter }).then(list => {
        commit(SET_COUNTERPARTY_LIST, list);
        commit(END, 'fetchCounterpartyList');
        return list;
      }).catch(() => {
        commit(END, 'fetchCounterpartyList');
      });
    },
    fetchCounterpartyDetail: ({ commit }, { id, version }) => {
      commit(START, 'fetchCounterpartyDetail');
      return api.counterpartyDetail({ id, version }).then((detail) => {
        commit(SET_COUNTERPARTY_CURRENT, detail);
        commit(END, 'fetchCounterpartyDetail');
        return detail;
      }).catch(() => {
        commit(END, 'fetchCounterpartyDetail');
      });
    },
    fetchRegistryList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchRegistryList');
      return api.registryList({ page, size, filter }).then(list => {
        commit(END, 'fetchRegistryList');
        commit(SET_REGISTRY_LIST, list)
        return list;
      }).catch(() => {
        commit(END, 'fetchRegistryList');
      });
    },
    downloadRegistryList: ({ commit }, { page, size, filter }) => {
      commit(START, 'downloadRegistryList');
      return api.downloadRegistryList({ page, size, filter }).then(list => {
        commit(END, 'downloadRegistryList');
        return list;
      }).catch(() => {
        commit(END, 'downloadRegistryList');
      });
    },
    updateCounterparty: ({ commit }, { id, items }) => {
      commit(START, 'updateCounterparty');
      return api.updateCounterparty({ id, items }).then((detail) => {
        commit(SET_COUNTERPARTY_CURRENT, detail);
        commit(END, 'updateCounterparty');
        return detail;
      }).catch(() => {
        commit(END, 'updateCounterparty');
      })
    },
    agreementCounterparty: ({ commit }, { id }) => {
      commit(START, 'agreementCounterparty');
      return api.agreementCounterparty({ id }).then(() => {
        commit(END, 'agreementCounterparty');
        return true;
      }).catch(() => {
        commit(END, 'agreementCounterparty');
        return false;
      });
    },
    createCounterparty: ({ commit }, { code }) => {
      commit(START, 'createCounterparty');
      return api.createCounterparty({ code }).then((result) => {
        commit(END, 'createCounterparty');
        return result;
      }).catch(() => {
        commit(END, 'createCounterparty');
      });
    },
    fetchCommentList: ({ commit }, { id }) => {
      commit(START, 'fetchCommentList');
      return api.commentList({ id }).then(list => {
        commit(SET_COMMENT_LIST, list);
        commit(END, 'fetchCommentList');
        return list;
      }).catch(() => {
        commit(END, 'fetchCommentList');
      });
    },
    createComment: ({ commit }, { id, question, message }) => {
      commit(START, 'createComment');
      return api.createComment({ id, question, message }).then((result) => {
        commit(END, 'createComment');
        return result;
      }).catch(() => {
        commit(END, 'createComment');
      });
    },
    deleteComment: ({ commit }, { id, comments }) => {
      commit(START, 'deleteComment');
      return api.deleteComment({ id, comments }).then((result) => {
        commit(END, 'deleteComment');
        return result;
      }).catch(() => {
        commit(END, 'deleteComment');
      });
    },
    editComment: ({ commit }, { id, message }) => {
      commit(START, 'editComment');
      return api.editComment({ id, message }).then((result) => {
        commit(SET_UPDATE_COMMENT, { id, message });
        commit(END, 'editComment');
        return result;
      }).catch((e) => {
        commit(END, 'editComment');
        throw e;
      });
    },
    sendDecision: ({ commit }, { id, decision, comment, notation }) => {
      commit(START, 'sendDecision');
      return api.sendDecision({ id, decision, comment, notation }).then(() => {
        commit(END, 'sendDecision');
        return true;
      }).catch(() => {
        commit(END, 'sendDecision');
        return false;
      });
    },
    fetchViewedQuestions: ({ commit }, { id }) => {
      commit(START, 'fetchViewedQuestions');
      return api.fetchCounterpartyViewedQuestions({ id }).then(items => {
        commit(SET_COUNTERPARTY_VIEWED_QUESTIONS, items);
        commit(END, 'fetchViewedQuestions');
        return items;
      }).catch(() => {
        commit(END, 'fetchViewedQuestions');
      });
    },
    setViewedQuestions: debounce(function({ commit, getters }, { id, questions }) {
      commit(START, 'setViewedQuestions');
      const {counterpartyViewedQuestions} = getters;
      commit(SET_COUNTERPARTY_VIEWED_QUESTIONS, {...counterpartyViewedQuestions, ...questions});
      return api.setCounterpartyViewedQuestions({ id, questions }).then(items => {
        commit(SET_COUNTERPARTY_VIEWED_QUESTIONS, items);
        commit(END, 'setViewedQuestions');
        return items;
      }).catch(() => {
        commit(SET_COUNTERPARTY_VIEWED_QUESTIONS, counterpartyViewedQuestions);
        commit(END, 'setViewedQuestions');
      });
    }, 500),
    fetchRisksList: ({ commit }) => {
      commit(START, 'fetchRisksList');
      return api.fetchRisks().then(items => {
        commit(SET_RISKS_LIST, items);
        commit(END, 'fetchRisksList');
        return items;
      }).catch(() => {
        commit(END, 'fetchRisksList');
      });
    },
    fetchRegistryCurrent: ({ commit }) => {
      commit(START, 'fetchRegistryCurrent');
      return api.registryCurrent().then(current => {
        commit(END, 'fetchRegistryCurrent');
        commit(SET_REGISTRY_CURRENT, current)
        return current;
      }).catch(() => {
        commit(END, 'fetchRegistryCurrent');
      });
    },
  },
}
