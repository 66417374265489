import { get, toString } from 'lodash-es';
import Api from '@/api';

export const imageSrc = (src) => {
  return (src && process.env.VUE_APP_PROXY) ? process.env.VUE_APP_PROXY + src : src;
}

export const fileUrl = (url) => {
  if (!url) return url;
  const result = url + `&sessid=${Api.sessid}`;
  return (url && process.env.VUE_APP_PROXY) ? process.env.VUE_APP_PROXY + result : result;
}

export const fileItem = (item) => ({
  id: toString(get(item, 'id')),
  name: toString(get(item, 'name')),
  size: toString(get(item, 'size')),
  src: imageSrc(toString(get(item, 'previewLink'))),
  url: fileUrl(toString(get(item, 'downloadLink'))),
  date: toString(get(item, 'dateCreate')),
});
