import {get, map, toString, toUpper, mapValues, toNumber, groupBy, flatten, values, keyBy, merge, filter, reduce, find} from 'lodash-es';
import {shortName} from '@/store/inspect/selectors';

export const section = item => {
  return {
    mark: toNumber(get(item, 'profileMark', 0)),
    label: toString(get(item, 'label')),
    sections: mapValues(get(item, 'sections'), section),
    questions: mapValues(get(item, 'questions'), (item, code) => ({
      code,
      type: toString(get(item, 'type', 'TEXT')),
      hint: toString(get(item, 'hint', '')),
      label: toString(get(item, 'label')),
      multiple: get(item, 'isMultiple', false),
      changed: !!get(item, 'isChanged', false),
      readonly: !!get(item, 'isReadonly', false),
      required: !!get(item, 'isRequired', false),
      rules: get(item, 'rules'),
      notAdmission: get(item, 'notAdmissionQuestions'),
      options: map(get(item, 'options'), (item) => ({
        text: toString(get(item, 'text')),
        value: toString(get(item, 'value')),
      })),
      values: groupBy(map(get(item, 'values'), (item) => ({
        id: toString(get(item, 'id')),
        type: toString(get(item, 'type', 'TEXT')),
        date: toString(get(item, 'dateExpire')),
        value: toString(get(item, 'value')),
        required: !!get(item, 'isRequired', false),
        readonly: !!get(item, 'isReadonly', false),
        deprecated: !!get(item, 'isDeprecated', false),
      })), 'type'),
    })),
  }
};

// Обратная функция к section
export const sectionPayload = item => ({
  label: toString(get(item, 'label')),
  sections: mapValues(get(item, 'sections'), sectionPayload),
  questions: mapValues(get(item, 'questions'), (item) => ({
    type: toString(get(item, 'type', 'TEXT')),
    label: toString(get(item, 'label')),
    isMultiple: get(item, 'multiple', false),
    isChanged: !!get(item, 'changed', false),
    isReadonly: !!get(item, 'readonly', false),
    options: map(get(item, 'options'), (item) => ({
      text: toString(get(item, 'text')),
      value: toString(get(item, 'value')),
    })),
    values: map(flatten(values(get(item, 'values'))), (item) => ({
      id: toString(get(item, 'id')),
      type: toString(get(item, 'type', 'TEXT')),
      dateExpire: toString(get(item, 'date')),
      value: toString(get(item, 'value')),
      isRequired: !!get(item, 'required', false),
      isReadonly: !!get(item, 'readonly', false),
      isDeprecated: !!get(item, 'deprecated', false),
    })),
  }))
});

export const counterpartyDetail = (result) => {
  return {
    id: toString(get(result, 'id')),
    inn: toString(get(result, 'author.companyInn')),
    name: toString(get(result, 'author.companyName')),
    reviewer: toString(get(result, 'reviewer.id')),
    isBlocked: !!get(result, 'isBlocked', false),
    dateEndBlock: toString(get(result, 'dateEndBlock', false)),
    hasApprovedForm: get(result, 'hasApprovedForm', false),
    status: {
      value: toString(get(result, 'status.xmlId')),
      text: toString(get(result, 'status.name')),
    },
    qualification: {
      value: toString(get(result, 'qualification.xmlId')),
      text: toString(get(result, 'qualification.name')),
    },
    mark: {
      full: {
        average: toNumber(get(result, 'marks.full.averageProfileMark', 0)),
        general: toNumber(get(result, 'marks.full.generalQualificationMark', 0)),
        integral: toNumber(get(result, 'marks.full.integralComprehensiveMark', 0)),
      },
      simple: {
        average: toNumber(get(result, 'marks.withoutGeneral.averageProfileMark', 0)),
        general: toNumber(get(result, 'marks.withoutGeneral.generalQualificationMark', 0)),
        integral: toNumber(get(result, 'marks.withoutGeneral.integralComprehensiveMark', 0)),
      },
    },
    items: mapValues(get(result, 'items'), section),
  };
};

export const counterpartyList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    id: toString(get(item, 'id')),
    inn: toString(get(item, 'author.companyInn')),
    name: toString(get(item, 'author.companyName')),
    status: {
      name: toString(get(item, 'lastApprovementStatus.name')),
      value: toString(get(item, 'lastApprovementStatus.xmlId')),
    },
    qualification: toString(get(item, 'qualification.name')),
  })),
  count: toNumber(get(result, 'navCount', 0)),
});

export const commentList = (result) => ({
  items: map(result.items, (item) => ({
    id: toString(get(item, 'id')),
    date: toString(get(item, 'dateCreated')),
    author: toString(get(item, 'author.name')),
    parent: toString(get(item, 'parentId') || 0),
    question: {
      id: toString(get(item, 'questionXmlId')),
      name: toString(get(item, 'questionText')),
    },
    message: toString(get(item, 'message')),
    draft: get(item, 'isDraft', false),
    actual: get(item, 'isActual', false),
  })),
  count: result.length,
});

export const registryList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    general: {
      text: 'Заказчик',
      items: {
        id: {
          text: 'ID',
          value: toString(get(item, 'id')),
        },
        userId: {
          text: 'ID пользователя',
          value: toString(get(item, 'userId')),
        },
        companyName: {
          text: 'Организация',
          value: toString(get(item, 'companyName')),
        },
        email: {
          text: 'Электронная почта',
          value: toString(get(item, 'email')),
        },
        phone: {
          text: 'Номер телефона',
          value: toString(get(item, 'phone')),
        },
        address: {
          text: 'Юр. адрес',
          value: toString(get(item, 'address')),
        },
        goods: {
          text: toString(get(item, 'goods.text')),
          value: toString(get(item, 'goods.value')),
        },
        numberOfEmployees: {
          text: toString(get(item, 'numberOfEmployees.text')),
          value: toString(get(item, 'numberOfEmployees.value')),
        },
      },
    },
    ...mapValues(keyBy(get(item, 'qualifications'), ({ code, name }) => code || name), (item) => ({
      text: toString(get(item, 'name')),
      items: {
        firstSegment: {
          text: toString(get(item, 'firstSegment.text')),
          value: get(item, 'firstSegment.value', false) ? 'Да': 'Нет',
        },
        secondSegment: {
          text: toString(get(item, 'secondSegment.text')),
          value: get(item, 'secondSegment.value', false) ? 'Да': 'Нет',
        },
        thirdSegment: {
          text: toString(get(item, 'thirdSegment.text')),
          value: get(item, 'thirdSegment.value', false) ? 'Да': 'Нет',
        },
        total: {
          text: 'Балл (общий по анкете)',
          value: get(item, 'generalQualificationMark', 0),
        }
      },
    })),
  })),
  count: toNumber(get(result, 'navCount', 0)),
});

export const timelineUser = (item, key) => ({
  code: toString(get(item, key + '.status.xmlId')),
  name: toString(get(item, key + '.status.name')),
  dateCreate: toString(get(item, key + '.date')),
  dateExec: toString(get(item, key + '.dateEnd')),
  reviewers: map(merge(
      filter([get(item, key + '.author')]),
      get(item, key + '.possibleReviewers', [])
    ),
    (reviewer) => ({
      id: get(reviewer, 'id'),
      name: shortName(reviewer),
    })
  ),
})

export const formRegistryList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    id: toString(get(item, 'id')),
    status: {
      code: toString(get(item, 'status.xmlId')),
      name: toString(get(item, 'status.name')),
    },
    dateSend: toString(get(item, 'dateSend')),
    dateStatus: toString(get(item, 'statusDate')),
    dateStatusEnd: toString(get(item, 'statusEndDate')),
    name: toString(get(item, 'author.companyName')),
    inn: toString(get(item, 'author.companyInn')),
    email: toString(get(item, 'author.email')),
    phone: toString(get(item, 'author.phone')),
    address: toString(get(item, 'author.address')),
    qualification: toString(get(item, 'qualification.name')),
    timelineManager: timelineUser(item, 'timelineManager'),
    timelineLawyer: timelineUser(item, 'timelineLawyer'),
    timelineReviewer: timelineUser(item, 'timelineReviewer'),
    timelineApprover: timelineUser(item, 'timelineApprover'),
  })),
  count: toNumber(get(result, 'navCount', 0)),
});

export const companiesList = (result) => map(get(result, 'items'), (item) => ({
  id: item.id,
  text: item.name,
  value: item.xmlId,
}));

export const approvalHistoryList = (result) => map(get(result, 'items'), (item, index) => {
  return {
    id: index,
    author: shortName(get(item, 'author')),
    status: item.status.name,
    code: item.status.xmlId,
    internalComment: toString(get(item, 'internalComment', '')),
    publicComment: toString(get(item, 'publicComment', '')),
    files: map(get(item, 'files', []), (value) => ({ value })),
    date: toString(get(item, 'dateCreate', '')),
    role: toString(get(item, 'reviewerName', ''))
  };
});

export const userReviewList = (result) => ({
  items: map(result.items, (item) => ({
    id: toString(get(item, 'id')),
    date: toString(get(item, 'dateCreated')),
    author: shortName(get(item, 'author')),
    message: toString(get(item, 'message')),
  })),
  count: result.length,
});

export const surveyGetForm = (result) => ({
  questions: map(result.questions, (item) => ({
    id: toString(get(item, 'id')),
    type: toUpper(toString(get(item, 'type'))),
    label: toString(get(item, 'text')),
    code: toString(get(item, 'xmlId')),
    hint: toString(get(item, 'hint')),
    options: map(get(item, 'listValues'), (item) => ({
      text: toString(get(item, 'text')),
      value: toString(get(item, 'value')),
    })),
    valueForComment: toString(get(item, 'valueForComment')),
    values: {
      'BOOL': {
        value: '',
      },
      'MARK': {
        value: '',
      },
      'TEXT': {
        value: '',
      },
    },
  })),
});

export const surveyGetList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    id: toString(get(item, 'id')),
    date: toString(get(item, 'dateCreate')),
    name: toString(get(item, 'author.companyName')),
    inn: toString(get(item, 'author.companyInn')),
    email: toString(get(item, 'author.email')),
    phone: toString(get(item, 'author.phone')),
    questions: reduce(get(item, 'questions'), (accumulator, question) => {
      const code =  toString(get(question, 'xmlId'));
      const type = toUpper(toString(get(question, 'type')));
      let value = '';
      const commentValue = get(question, 'commentValue', '');
      const boolValue = get(find(get(question, 'listValues'), { value: get(question, 'value')}), 'text', '');
      const simpleValue = get(question, 'value');
      if (type === 'BOOLEAN_WITH_COMMENT') {
        value = `${boolValue}. ${commentValue}`;
      } else if (type === 'TEXT') {
        value = simpleValue;
      } else if (type === 'MARK_WITH_COMMENT') {
        value = `${simpleValue}. ${commentValue}`;
      }
      return {
        ...accumulator,
        [code]: {
          id: toString(get(question, 'id')),
          type: toUpper(toString(get(question, 'type'))),
          label: toString(get(question, 'text')),
          code: toString(get(question, 'xmlId')),
          hint: toString(get(question, 'hint')),
          options: map(get(question, 'listValues'), (option) => ({
            text: toString(get(option, 'text')),
            value: toString(get(option, 'value')),
          })),
          value: value,
        }
      };
    }, {}),
  })),
  count: toNumber(get(result, 'navCount', 0)),
});

export const surveyFormPayload = fields => ([
  ...map(fields, (item) => {
    let value = '';
    let commentValue = '';
    if (get(item, 'type') === 'BOOLEAN_WITH_COMMENT') {
      value = get(item, 'values.BOOL.value', '');
      commentValue = get(item, 'values.TEXT.value', '');
    } else if (get(item, 'type') === 'TEXT') {
      value = get(item, 'values.TEXT.value', '');
    } else if (get(item, 'type') === 'MARK_WITH_COMMENT') {
      value = get(item, 'values.MARK.value', '');
      commentValue = get(item, 'values.TEXT.value', '');
    }
    return {
      id: toString(get(item, 'id')),
      value,
      commentValue,
    };
  }),
]);
