import { get, toNumber, toString, omit, merge } from 'lodash-es';

export default [
  {
    path: '/inspect/inspectors',
    name: 'inspect/InspectorCompanyListView',
    meta: { title: 'Рейтинг инспекционных компаний' },
    props: route => ({
      filter: {
        ...omit( get(route, 'query'), ['page', 'size']),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/inspect/InspectorListView/InspectorListView'),
  },
  {
    path: '/inspect/inspectors/:master',
    name: 'inspect/InspectorListView',
    meta: { title: 'Список инспекторов компании' },
    props: route => ({
      filter: {
        ...omit( get(route, 'query'), ['page', 'size']),
        masterId: toString(get(route, 'params.master')),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/inspect/InspectorListView/InspectorListView'),
  },
  {
    path: '/inspect/inspectors/:master/applications/:inspector',
    name: 'inspect/InspectorDetailView',
    meta: { title: 'Заявки инспектора' },
    props: route => ({
      id: toString(get(route, 'params.inspector')),
      filter: merge(omit(get(route, 'query'), ['page', 'size']), {
        'inspectorId': toString(get(route, 'params.inspector')),
      }),
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/inspect/InspectorDetailView/InspectorDetailView'),
  },
  {
    path: '/inspect/',
    name: 'inspect/ApplicationListView',
    meta: { title: 'Список заявок' },
    props: route => ({
      filter: omit( get(route, 'query'), ['page', 'size']),
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/inspect/ApplicationListView/ApplicationListView'),
  },
  {
    path: '/inspect/application/:application',
    name: 'inspect/ApplicationDetailView',
    meta: { title: 'Заявка' },
    props: route => ({
      id: toString(get(route, 'params.application')),
      filter: omit( get(route, 'query'), ['page', 'size']),
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 5)),
    }),
    component: () => import('@/views/inspect/ApplicationDetailView/ApplicationDetailView'),
  },
  {
    path: '/inspect/application/:application/chat',
    name: 'inspect/AppReportCommentsView',
    meta: { title: 'Чат инспекции' },
    props: route => ({
      id: toString(get(route, 'params.application')),
      code: toString(get(route, 'params.code')),
    }),
    component: () => import('@/views/inspect/ApplicationDetailView/ApplicationDetailView'),
  },
  {
    path: '/inspect/application/:application/comments/:code',
    name: 'inspect/ApplicationCommentsView',
    meta: { title: 'Комментарии' },
    props: route => ({
      id: toString(get(route, 'params.application')),
      code: toString(get(route, 'params.code')),
    }),
    component: () => import('@/views/inspect/ApplicationDetailView/ApplicationDetailView'),
  },
  {
    path: '/inspect/application/:application/inspectors',
    name: 'inspect/ApplicationInspectorBindingListView',
    meta: { title: 'Добавление инспекторов в проект' },
    props: route => ({
      application: toString(get(route, 'params.application')),
      filter: merge(omit(get(route, 'query'), ['page', 'size']), {
        '!applicationId': toString(get(route, 'params.application')),
      }),
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/inspect/ApplicationInspectorBindingListView/ApplicationInspectorBindingListView'),
  },
  {
    path: '/inspect/:catchAll(.*)',
    redirect: { name: 'inspect/ApplicationListView' }
  },
];
