import Vue from 'vue';
import { get, includes } from 'lodash-es';
import api from './api';
const START = 'START';
const END = 'END';
const SET_APPLICATION_LIST = 'SET_APPLICATION_LIST';
const SET_APPLICATION_CURRENT = 'SET_APPLICATION_CURRENT';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchApplicationList: false,
      fetchApplicationDetail: false,
      updateApplication: false,
      approveActionApplication: false,
    },
    application: {
      current: null,
      list: {
        items: [],
        count: 0,
      },
    },
  },
  getters: {
    pending(state) {
      return includes(state.pending, true);
    },
    application: (state) => {
      return get(state, 'application.current');
    },
    applications: (state) => {
      return state.application.list;
    },
  },
  mutations: {
    [START]: (state, key) => {
      Vue.set(state.pending, key, true);
    },
    [END]: (state, key) => {
      Vue.set(state.pending, key, false);
    },
    [SET_APPLICATION_LIST]: (state, list) => {
      state.application.list = list;
    },
    [SET_APPLICATION_CURRENT]: (state, current) => {
      state.application.current = current;
    },
  },
  actions: {
    fetchApplicationList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchApplicationList');
      return api.applicationList({ page, size, filter }).then(list => {
        commit(SET_APPLICATION_LIST, list);
        commit(END, 'fetchApplicationList');
        return list;
      }).catch(() => {
        commit(END, 'fetchApplicationList');
      });
    },
    fetchApplicationDetail: ({ commit }, { id }) => {
      commit(START, 'fetchApplicationDetail');
      return api.applicationDetail({ id }).then((detail) => {
        commit(SET_APPLICATION_CURRENT, detail);
        commit(END, 'fetchApplicationDetail');
        return detail;
      }).catch(() => {
        commit(END, 'fetchApplicationDetail');
      });
    },
    updateApplication: ({ commit }, { id, fields }) => {
      commit(START, 'updateApplication');
      return api.updateApplication({ id, fields }).then((detail) => {
        commit(SET_APPLICATION_CURRENT, detail);
        commit(END, 'updateApplication');
        return detail;
      }).catch(() => {
        commit(END, 'updateApplication');
      })
    },
    approveActionApplication: ({ commit }, { id, action, comment  }) => {
      commit(START, 'approveActionApplication');
      return api.approveActionApplication({ id, action, comment }).then(() => {
        commit(END, 'approveActionApplication');
        return true;
      }).catch(() => {
        commit(END, 'approveActionApplication');
        return false;
      });
    },
  },
}