import { get, toNumber, toString } from 'lodash-es';

export default [
  {
    path: '/pkopir/',
    name: 'pkopir/CounterpartyListView',
    meta: { title: 'Список заявок' },
    props: route => ({
      filter: {
        id: toString(get(route, 'query.id', '')),
        inn: toString(get(route, 'query.inn', '')),
        companyName: toString(get(route, 'query.companyName', '')),
        status: toString(get(route, 'query.status', '')),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/pkopir/CounterpartyListView/CounterpartyListView'),
  },
  {
    path: '/pkopir/registry/',
    name: 'pkopir/RegistryListView',
    meta: { title: 'Рейтинг контрагентов' },
    props: route => ({
      filter: {
        id: toString(get(route, 'query.id', '')),
        inn: toString(get(route, 'query.inn', '')),
        companyName: toString(get(route, 'query.companyName', '')),
        status: toString(get(route, 'query.status', '')),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/pkopir/RegistryListView/RegistryListView'),
  },
  {
    path: '/pkopir/:counterparty/:question?',
    name: 'pkopir/CounterpartyDetailView',
    meta: { title: 'Заявка' },
    props: route => ({
      counterparty: toString(get(route, 'params.counterparty')),
      question: toString(get(route, 'params.question'))
    }),
    component: () => import('@/views/pkopir/CounterpartyDetailView/CounterpartyDetailView'),
  },
  {
    path: '/pkopir/:catchAll(.*)',
    redirect: { name: 'pkopir/CounterpartyListView' }
  },
];
