import Vue from 'vue';
import VueRouter from 'vue-router';
import nda from './nda';
import user from './user';
import elkp from './elkp';
import docs from './docs';
import actsvr from './actsvr';
import pkopir from './pkopir';
import pkosmr from './pkosmr';
import pkomain from './pkomain';
import inspect from './inspect';
import account from './account';
import accountUsers from './account-users';
import pko from './pko';
import spa from './spa';
import { getSectionEntity } from "@/utils/getSectionEntity";
import { merge } from "lodash-es";

Vue.use(VueRouter);

// eslint-disable-next-line no-new
const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes: [
    ...nda,
    ...user,
    ...elkp,
    ...docs,
    ...actsvr,
    ...pkopir,
    ...pkosmr,
    ...pkomain,
    ...inspect,
    ...pko,
    ...spa,
    ...account,
    ...accountUsers,
    {
      path: '/:catchAll(.*)',
      name: 'NotFound',
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (from.meta && from.meta.beforeEach) {
    from.meta.beforeEach(to, from, next);
  } else {
    next();
  }
})

router.afterEach((to) => {
  to.meta.info = merge(to.meta.info, getSectionEntity(to));
})

export default router;
