import Api from '@/api';
import { map, filter, isArray } from 'lodash-es';
import { contractList, contractDetail, employeeList, employeeDetail, employeeData, commentList } from './selectors';

class ELKP extends Api {
  // Список контрактов с фильтром
  contractList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/elkp/profile-list.json', {
      method: 'POST',
      data: { page, size, filter }
    }).then(contractList);
  }
  contractDetail({ id }) {
    return this.rest('/elkp/profile-item-get.json', {
      method: 'POST',
      data: { id }
    }).then(contractDetail);
  }
  createContract({ inn, date, name, number }) {
    return this.rest('/elkp/profile-item-create.json', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: {
        contractNumber: number,
        contractExpireDate: date,
        author: {
          companyName: name,
          companyInn: inn,
        }
      }
    });
  }
  updateContract({ id, hours }) {
    return this.rest('/elkp/profile-item-save.json', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: JSON.stringify({
        id,
        workHours: filter(map(hours, ({ value, current, disabled }) => ({
          value,
          canEdit: !disabled,
          isCurrent: current,
        })), 'canEdit'),
      })
    });
  }
  deleteContract({ id }) {
    return this.rest('/elkp/profile-item-delete.json', {
      method: 'POST',
      data: { id }
    });
  }
  setWorkTimeToContract({ id, values }) {
    return this.rest('/elkp/profile-work-time-set.json', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: {
        profileId: id,
        items: filter(map(values, ({ value, current, disabled }) => ({
          value,
          canEdit: !disabled,
          isCurrent: current,
        })), 'canEdit'),
      },
    })
  }
  //
  employeeList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/elkp/employee-list.json', {
      method: 'POST',
      data: { page, size, filter }
    }).then(employeeList);
  }
  employeeDetail({ id }) {
    return this.employeeFields().then(fields => {
      return this.rest('/elkp/employee-item-get.json', {
        method: 'POST',
        data: { id }
      }).then(employee => employeeDetail(employee, fields));
    })
  }
  employeeFields() {
    return this.rest(
      '/elkp/question-list.json'
    )
  }
  createEmployee({ id, values }) {
    return this.rest('/elkp/employee-save.json', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: JSON.stringify({
        profileId: id,
        values: employeeData(values),
      }),
    })
  }
  updateEmployee({ id, values }) {
    return this.rest('/elkp/employee-edit.json', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        employeeId: id,
        values: employeeData(values),
      }),
    })
  }
  addEmployeeToContract({ employees, contract }) {
    return this.rest('/elkp/employee-profile-bind.json',{
      method: 'POST',
      data: {
        profileId: isArray(contract) ? contract : [contract],
        employees: isArray(employees) ? employees : [employees],
      }
    })
  }
  removeEmployeeFromContract({ employees, contract }) {
    return this.rest('/elkp/employee-profile-unbind.json',{
      method: 'POST',
      data: {
        profileId: isArray(contract) ? contract : [contract],
        employees: isArray(employees) ? employees : [employees],
      }
    }).then(({ result }) => !!result)
  }
  commentList({ id }) {
    return this.rest('/elkp/answer-comments-get.json', {
      method: 'POST',
      data: { employeeId: id }
    }).then(commentList);
  }
  createComment({ id, employee, message }) {
    return this.rest('/elkp/answer-comments-save.json', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: {
        comments: [{
          employeeId: employee,
          questionId: id,
          message: message,
        }],
      },
    })
  }
  viewedComments({ items }) {
    return this.rest('/elkp/answer-comments-mark-viewed.json', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: {
        items,
      },
    })
  }
}

export default new ELKP();
