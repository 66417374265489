import Vue from 'vue';
import { find } from 'lodash-es';
import VueSweetalert2 from 'vue-sweetalert2';
import Notifications from 'vue-notification';
import VueMask from 'v-mask'
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import '@mdi/font/css/materialdesignicons.min.css';
import '@sweetalert2/theme-minimal/minimal.css';

Vue.use(VueSweetalert2, {
  confirmButtonColor: '#f57f29',
  denyButtonColor: '#ff5252',
  cancelButtonColor: '#f5f5f5',
  confirmButtonText: 'Да',
  denyButtonText: 'Нет',
  cancelButtonText: 'Отмена',
  showCancelButton: true,
});

Vue.use(Notifications);
Vue.use(VueMask);
Vue.config.productionTip = false;

setInterval(() => store.dispatch('user/checkSession'), 10 * 60 * 1000); //  5 мин;


store.dispatch('user/prefetchUser')
.catch(() => store.dispatch('user/prefetchUser'))
.then(() => {
  const redirect = new URLSearchParams(window.location.search).get('redirect');
  if (redirect) window.location = redirect;
}) // TODO: вернуть на страницу после получения пользователя
.catch(() => { // Редирект на главную при отсутствии авторизации
  const path = window.location.pathname;
  if (find(['nda', 'elkp', 'pkopir', 'pkosmr', 'pkomain', 'inspect', 'documents'], (url) => !!~path.indexOf(url))) {
    if (!~['/', '/forgot', '/registration', '/auth'].indexOf(window.location.pathname)) { // TODO: не покрывает все случаи
      const portal = new URLSearchParams(window.location.search).get('portal');
      if (portal) {
        window.location = `/?redirect=${window.location.toString().substring(window.location.origin.length)}&portal=${portal}`;
      } else {
        window.location = `/?redirect=${window.location.toString().substring(window.location.origin.length)}`;
      }
    }
  }
})
.finally(() => {
  const path = window.location.pathname;
  if (!find(['/auth/confirm.php'], (url) => !!~path.indexOf(url))) { // Не инициализируется на страницах
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app');
  }
})


// TODO: Функция авторизации под пользователем
if (process.env.VUE_APP_USER) {
  document.addEventListener('keydown',function(e){
    if(e.ctrlKey && e.altKey){
      switch(e.code){
        case 'KeyU':
          import('@/api/authorization').then((module) => {
            module.default();
          });
          break;
      }
    }
  });
}
