import Vue from 'vue';
import {get, includes, omit, hasIn, keyBy, debounce, keys, omitBy} from 'lodash-es';
import api from './api';
const START = 'START';
const END = 'END';
const ADD_TO_QUEUE = 'ADD_TO_QUEUE';
const SET_ITEM = 'SET_ITEM';
const SET_LIST = 'SET_LIST';
const DELETE_ITEM = 'DELETE_ITEM';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchItem: false,
      uploadItem: false,
      fetchList: false,
      downloadItem: false,
    },
    items: {},
  },
  getters: {
    pending(state) {
      return includes(state.pending, true);
    },
    queue: (state) => {
      return keys(omitBy(get(state, 'items'), Boolean));
    },
    items: (state) => {
      return get(state, 'items');
    },
  },
  mutations: {
    [START]: (state, key) => {
      Vue.set(state.pending, key, true);
    },
    [END]: (state, key) => {
      Vue.set(state.pending, key, false);
    },
    [ADD_TO_QUEUE]: (state, id) => {
      Vue.set(state.items, id, null);
    },
    [SET_ITEM]: (state, file) => {
      Vue.set(state.items, file.id, file);
    },
    [SET_LIST]: (state, items) => {
      Vue.set(state, 'items', { ...state.items, ...items });
    },
    [DELETE_ITEM]: (state, id) => {
      Vue.set(state, 'items', omit(state.items, [id]));
    }
  },
  actions: {
    addToQueue: ({ commit, dispatch }, { id, section, entity }) => {
      commit(ADD_TO_QUEUE, id);
      dispatch('fetchQueue', { section, entity });
    },
    fetchQueue: debounce(function({ commit, getters }, { section, entity }) {
      const { queue } = getters;
      commit(START, 'fetchQueue');
      return api.list({ id: queue, section, entity }).then(items => {
        commit(SET_LIST, keyBy(items, 'id'));
        commit(END, 'fetchQueue');
        return items;
      }).catch(() => {
        commit(END, 'fetchQueue');
      });
    }, 500),
    fetchItem: ({ commit, getters }, { id }) => {
      commit(START, 'fetchItem');
      if (!hasIn(getters['items'], id)) {
        return api.item({ id }).then((item) => {
          commit(SET_ITEM, item);
          commit(END, 'fetchItem');
          return item;
        }).catch(() => {
          commit(END, 'fetchItem');
        });
      }
    },
    fetchList: ({ commit }, { id }) => {
      commit(START, 'fetchList');
      return api.list({ id }).then(items => {
        commit(SET_LIST, keyBy(items, 'id'));
        commit(END, 'fetchList');
        return items;
      }).catch(() => {
        commit(END, 'fetchList');
      });
    },
    uploadList: ({ commit }, {files, section, entity}) => {
      commit(START, 'uploadList');
      return api.uploadList(files, {section, entity}).then(items => {
        commit(SET_LIST, keyBy(items, 'id'));
        commit(END, 'uploadList');
        return items;
      }).catch(() => {
        commit(END, 'uploadList');
      });
    },
    uploadItem: ({ commit }, files) => {
      commit(START, 'uploadItem');
      return api.upload(files).then(item => {
        commit(SET_ITEM, item);
        commit(END, 'uploadItem');
        return item;
      }).catch(() => {
        commit(END, 'uploadItem');
      });
    },
    deleteItem: ({ commit }, { id }) => {
      commit(START, 'deleteItem');
      return api.delete({ id }).then((success) => {
        commit(DELETE_ITEM, id);
        commit(END, 'deleteItem');
        return success;
      }).catch(() => {
        commit(END, 'deleteItem');
        return false;
      })
    }
  },
}
