import Api from '@/api';
import { documentDetail } from './selectors';

class DOCS extends Api {
  documentDetail({ id }) {
    if (!id) return Promise.resolve(documentDetail());
    return this.rest('/documents/user/items.json',{
      method: 'POST',
      data: { id },
    })
    .then(documentDetail);
  }
  createDocument({ id, idParent, idTypeDoc, dateFrom, dateTo }) {
    return this.rest('/documents/user/file-save', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: {
        id,
        idParent,
        idTypeDoc,
        dateFrom,
        dateTo,
      },
    });
  }
}

export default new DOCS();
