import {isArray, map, mapValues, get} from 'lodash-es';
import Api from '@/api';
import {
  counterpartyDetail,
  counterpartyList,
  sectionPayload,
  commentList,
  registryList,
  companiesList,
  approvalHistoryList,
  formRegistryList,
  userReviewList,
  surveyGetForm,
  surveyFormPayload,
  surveyGetList,
} from './selectors';
import {approvalList} from '@/store/pkomain/selectors';

class PKOSMR extends Api {
  counterpartyList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/pkosmr/form/get-list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(counterpartyList);
  }
  counterpartyDetailWithOutApproval({ id }) {
    return this.rest('/pkosmr/form/get.json',{
      method: 'POST',
      data: {
        id,
      },
    })
      .then(counterpartyDetail);
  }
  counterpartyDetail({ id }) {
    return Promise.all([
      this.counterpartyDetailWithOutApproval({ id }),
      this.approvalStatus({ id }),
      this.approvalHistory({ id }),
    ]).then(([counterparty, approval, approvalHistory]) => {
      return {
        approval,
        approvalHistory,
        ...counterparty,
      }
    });
  }
  approvalHistory({ id }) {
    return this.rest('/pkosmr/form/get-approval-history.json', {
      method: 'POST',
      data: {
        id: id,
      },
    }).then(approvalHistoryList)
  }
  approvalStatus({ id }) {
    return this.rest('/pkosmr/form/get-approval-timeline.json', {
      method: 'POST',
      data: {
        id: id,
      },
    }).then(approvalList)
  }
  editApprovalTask({ id, reviewerId }) {
    return this.rest('/pkosmr/form/edit-approval-task.json', {
      method: 'POST',
      data: {
        id,
        reviewerId,
      }
    });
  }
  registryList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/pkosmr/userstatusregistry/list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(registryList);
  }
  downloadRegistryList({ page = 1, size = 10, filter = {} }) {
    return this.download('/pkosmr/userstatusregistry/list-excel.json', {
      params: { page, size, filter },
    });
  }

  formRegistryList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/pkosmr/formregistry/list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(formRegistryList);
  }
  downloadFormRegistryList({ page = 1, size = 10, filter = {} }) {
    return this.download('/pkosmr/formregistry/list-excel.json', {
      params: { page, size, filter },
    });
  }
  updateCounterparty({ id, items }) {
    return this.rest('/pkosmr/form/edit.json', {
      method: 'POST',
      data: {
        id,
        items: mapValues(items, sectionPayload),
      },
    }).then(counterpartyDetail);
  }
  agreementCounterparty({ id }) {
    return this.rest('/pkosmr/form/send-on-approvement.json', {
      method: 'POST',
      data: {
        id,
      },
    });
  }
  commentList({ id }) {
    return this.rest('/pkosmr/comment/get-list.json', {
      method: 'POST',
      data: { formId: id },
    }).then(commentList);
  }
  createComment({ id, parent, question, message }) {
    return this.rest('/pkosmr/comment/create-many.json', {
      method: 'POST',
      data: {
        formId: id,
        comments: map(isArray(question) ? question : [question], (code) => ({
          message,
          parentId: parent,
          questionXmlId: code,
        }))
      },
    });
  }
  deleteComment({ id, comments }) {
    return this.rest('/pkosmr/comment/delete-many.json', {
      method: 'POST',
      data: {
        formId: id,
        comments: isArray(comments) ? comments : [comments],
      },
    });
  }
  approveComment({ items }) {
    return this.rest('/pkosmr/comment/approve-comment.json', {
      method: 'POST',
      data: {
        items: isArray(items) ? items: [items],
      },
    });
  }
  editComment({ id, message }) {
    return this.rest('/pkosmr/comment/edit.json', {
      method: 'POST',
      data: {
        id,
        comment: message,
      },
    });
  }
  sendDecision({ id, decision, comment, notation, expertMark, files } ) {
    return this.rest('/pkosmr/form/save-decision.json', {
      method: 'POST',
      data: {
        id: id,
        decision: decision, /// YES, REWORK, NO
        publicComment: comment,
        internalComment: notation,
        expertMark: expertMark,
        files: map(files, (file) => get(file, 'value')),
      },
    })
  }
  createCounterparty({ code }) {
    return this.rest('/pkosmr/form/create.json', {
      method: 'POST',
      data: { qualificationCode: code },
    });
  }
  companiesForCreate() {
    return this.rest('/pkosmr/form/qualification-list').then(companiesList);
  }
  updateReviewer ({ id }) {
    return this.rest('/pkosmr/form/update-reviewer.json', {
      method: 'POST',
      data: { id },
    })
  }
  userReviewList({ id }) {
    return this.rest('/pkosmr/userstatusregistry/user-review-list.json', {
      method: 'POST',
      data: { userId: id },
    }).then(userReviewList);
  }
  createUserReview({ id, message }) {
    return this.rest('/pkosmr/userstatusregistry/create-user-review.json', {
      method: 'POST',
      data: {
        userId: id,
        message,
      },
    });
  }
  surveyGetForm({ id }) {
    return this.rest('/pkosmr/survey/get-form.json', {
      method: 'POST',
      data: {
        id: id,
      },
    }).then(surveyGetForm);
  }
  surveySaveForm({ id, fields }) {
    return this.rest('/pkosmr/survey/save-form.json', {
      method: 'POST',
      data: {
        id: id,
        questions: surveyFormPayload(fields),
      },
    });
  }
  surveyGetList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/pkosmr/survey/get-form-list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(surveyGetList);
  }
}

export default new PKOSMR();
