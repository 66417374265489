import { get, toString } from 'lodash-es';

export default [
  {
    path: '/nda/',
    name: 'nda/DisclosureAgreementView',
    meta: { title: 'Соглашение о конфиденциальности' },
    props: route => ({
      agreement: toString(get(route, 'query.appId')),
      template: toString(get(route, 'query.template')),
    }),
    component: () => import('@/views/nda/DisclosureAgreementView/DisclosureAgreementView'),
  },
  {
    path: '/nda/:catchAll(.*)',
    redirect: { path: '/', query: {} }
  },
];
