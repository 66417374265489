import Api from '@/api';
import {accountUsersList, accountUsersDetail, accountUsersRoles, accountUsersEmployees} from './selectors';

class AccountUsers extends Api {
  getAccountUsersDetail({ id }) {
    return this.rest('/account-users/get-employee.json', {
      method: 'POST',
      data: { id },
    }).then(accountUsersDetail);
  }

  getAccountUsersList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/account-users/list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(accountUsersList);
  }

  getAccountUsersRoles() {
    return this.rest('/account-users/roles-list.json', {
      method: 'GET',
    }).then(accountUsersRoles);
  }

  getAccountUsersEmployees({ accountId, filter, page, size }) {
    return this.rest('/account-users/employee-directory-list.json', {
      method: 'POST',
      data: { accountId, filter, page, size }
    }).then(accountUsersEmployees);
  }

  addAccountUsersEmployees({ id, accountId }) {
    return this.rest('/account-users/add-employee-directory.json', {
      method: 'POST',
      data: { id, accountId }
    }).then(accountUsersEmployees);
  }

  createAccountUsers({ accountId, fields }) {
    return this.rest('/account-users/add-employee.json', {
      method: 'POST',
      data: {
        item: { accountId, ...fields }
      }
    }).then(accountUsersDetail);
  }

  updateAccountUsers({ id, fields }) {
    return this.rest('/account-users/edit-employee.json', {
      method: 'POST',
      data: {
        item: {
          id,
          ...fields,
        },
      }
    }).then(accountUsersDetail);
  }

  deleteAccountUsers({ id }) {
    return this.rest('/account-users/delete-employee.json', {
      method: 'POST',
      data: { id },
    });
  }
}

export default new AccountUsers();







