import {get, map, toString, toNumber, compact} from 'lodash-es';
import {shortName} from '@/store/inspect/selectors';

export const accountTimeLine = (result) => {
  return map(get(result, 'items'), (item) => {
    return {
      dateCreate: toString(get(item, 'dateCreate')),
      comment: toString(get(item, 'message')),
      prevStatus: {
        code:toString(get(item, 'prevStatus.code')),
        title: toString(get(item, 'prevStatus.title')),
      },
      newStatus: {
        code:toString(get(item, 'newStatus.code')),
        title: toString(get(item, 'newStatus.title')),
      },
      author: {
        id: toString(get(item, 'author.id')),
        name: toString(get(item, 'author.name')),
        lastName: toString(get(item, 'author.lastName')),
        secondName: toString(get(item, 'author.secondName')),
        email: toString(get(item, 'author.email')),
        companyName: toString(get(item, 'author.companyName')),
        companyInn: toString(get(item, 'author.companyInn')),
        phone: toString(get(item, 'author.phone')),
        address: toString(get(item, 'author.address')),
        firstGenContact: toString(get(item, 'author.firstGenContact')),
        companyOgrn: toString(get(item, 'author.companyOgrn')),
      },
    }
  })
}

export const accountItem = (item) => {
  return {
    id: toString(get(item, 'id')),
    type: toString(get(item, 'type')),
    status: toString(get(item, 'status')),
    commentToDelete: toString(get(item, 'commentToDelete')),
    dateCreate: toString(get(item, 'dateCreate')),
    changeUser: toString(get(item, 'changeUser')),
    dateChange: toString(get(item, 'dateChange')),
    isActive: toString(get(item, 'isActive')),
    xmlId: toString(get(item, 'xmlId')),
    valuesVersionId: toNumber(get(item, 'valuesVersionId')),
    codeKsc: toString(get(item, 'codeKsc')),
    inn: toString(get(item, 'inn')),
    kpp: toString(get(item, 'kpp')),
    passportNumber: toString(get(item, 'passportNumber')),
    passportDate: toString(get(item, 'passportDate')),
    companyNameFull: toString(get(item, 'companyNameFull')),
    companyNameShort: toString(get(item, 'companyNameShort')),
    ogrn: toString(get(item, 'ogrn')),
    verificationLetter: compact([toString(get(item, 'verificationLetter'))]),
    companyCharter: compact([toString(get(item, 'companyCharter'))]),
    companyEgrul: compact([toString(get(item, 'companyEgrul'))]),
    companyElectronProtocol: compact([toString(get(item, 'companyElectronProtocol'))]),
    companyNalogCert: compact([toString(get(item, 'companyNalogCert'))]),
    companyOgrnCert: compact([toString(get(item, 'companyOgrnCert'))]),
    companyDirectorOrder: compact([toString(get(item, 'companyDirectorOrder'))]),
    address: {
      country: toString(get(item, 'address.country')),
      region: toString(get(item, 'address.region')),
      zipCode: toString(get(item, 'address.zipCode')),
      city: toString(get(item, 'address.city')),
      street: toString(get(item, 'address.street')),
      house: toString(get(item, 'address.house')),
      building: toString(get(item, 'address.building')),
      flat: toString(get(item, 'address.flat')),
      countryIsoCode: toString(get(item, 'address.countryIsoCode')),
      regionIsoCode: toString(get(item, 'address.regionIsoCode')),
    },
    author: {
      id: toString(get(item, 'author.id')),
      name: toString(get(item, 'author.name')),
      lastName: toString(get(item, 'author.lastName')),
      secondName: toString(get(item, 'author.secondName')),
      fio: shortName(get(item, 'author')),
      email: toString(get(item, 'author.email')),
      companyName: toString(get(item, 'author.companyName')),
      companyInn: toString(get(item, 'author.companyInn')),
      phone: toString(get(item, 'author.phone')),
      address: toString(get(item, 'author.address')),
      firstGenContact: toString(get(item, 'author.firstGenContact')),
      companyOgrn: toString(get(item, 'author.companyOgrn')),
    },
  }
}

export const accountList = (result) => {
  return {
    navCount: get(result, 'navCount'),
    navPage: get(result, 'navPage'),
    navPageCount: get(result, 'navPageCount'),
    navPageSize: get(result, 'navPageSize'),
    items: map(get(result, 'items'), (item) => accountItem(item)),
  }
};

export const accountDetail = (result) => {
  return {
    ...accountItem(get(result, 'item', {})),
  }
};
