
export default [
  {
    path: '/pko/',
    name: 'pko/PkoDashboardView',
    meta: { title: 'Квалификационные опросы и отбор контрагентов' },
    component: () => import('@/views/pko/PkoDashboardView/PkoDashboardView'),
  },
  {
    path: '/pko/:catchAll(.*)',
    redirect: { name: 'pko/PkoDashboardView' }
  },
];