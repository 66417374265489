import { get, includes} from 'lodash-es';
import api from './api';

const START = 'START';
const END = 'END';

const SET_ACCEPTANCE_LIST = 'SET_ACCEPTANCE_LIST';
const SET_ACCEPTANCE_CURRENT = 'SET_ACCEPTANCE_CURRENT';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchAcceptanceList: false,
      fetchAcceptanceDetail: false,
    },
    acceptance: {
      current: null,
      list: {
        items: [],
        count: 0,
      },
    },
  },
  getters: {
    pending: (state) => {
      return includes(state.pending, true);
    },
    acceptance: (state) => {
      return get(state, 'acceptance.current');
    },
    acceptances: (state) => {
      return state.acceptance.list;
    },
  },
  mutations: {
    [START]: (state, key) => {
      state.pending[key] = true;
    },
    [END]: (state, key) => {
      state.pending[key] = false;
    },
    [SET_ACCEPTANCE_LIST]: (state, list) => {
      state.acceptance.list = list;
    },
    [SET_ACCEPTANCE_CURRENT]: (state, current) => {
      state.acceptance.current = current;
    },
  },
  actions: {
    fetchAcceptanceList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchAcceptanceList');
      return api.acceptanceList({ page, size, filter }).then(list => {
        commit(SET_ACCEPTANCE_LIST, list);
        commit(END, 'fetchAcceptanceList');
        return list;
      }).catch(() => {
        commit(END, 'fetchAcceptanceList');
      });
    },
    fetchAcceptanceDetail: ({ commit }, { id }) => {
      commit(START, 'fetchAcceptanceDetail');
      return api.acceptanceDetail({ id }).then((detail) => {
        commit(SET_ACCEPTANCE_CURRENT, detail);
        commit(END, 'fetchAcceptanceDetail');
        return detail;
      }).catch(() => {
        commit(END, 'fetchAcceptanceDetail');
      });
    },
  },
}
