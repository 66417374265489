<template>
  <VApp :class="$style.root">
    <VMain>
      <VContainer fluid>
        <VRow>
          <VCol cols="12">
            <Logo />
          </VCol>
          <VCol cols="12">
            <UserLoginView v-if="$route.name === 'user/ProfileView'" />
            <router-view v-else />
          </VCol>
        </VRow>
      </VContainer>
      <NotificationLog />
    </VMain>
    <VFooter color="#f7f7f7" class="justify-center">
      <small>© {{new Date().getFullYear()}}, ЕВРАЗ. Все права защищены</small>
    </VFooter>
  </VApp>
</template>

<script>
import Logo from '@/components/general/Logo/Logo';
import NotificationLog from '@/views/general/NotificationLog/NotificationLog';
import UserLoginView from '@/views/user/UserLoginView/UserLoginView';
export default {
  name: 'LayoutAuth',
  components: {
    Logo,
    UserLoginView,
    NotificationLog,
  },
}
</script>

<style lang="scss" module>
.root {
  &:global(.theme--light.v-application) {
    background-image: inherit;
    background-repeat: inherit;
    background-color: #f7f7f7 ;
  }
}
</style>
